import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from '@handwerk-pwa/shared';
import { PwaLoginResponse, Right, Role, UserInfo } from 'libs/shared/src/lib/entities';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { AuthorizationService } from 'libs/shared/src/lib/services/authorization.service';
import { BrowserService } from 'libs/shared/src/lib/services/browser.service';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { RoleService } from 'libs/shared/src/lib/services/role.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { UserInfoHelper } from '../../../helper/userInfoHelper';

@Component({
  selector: 'app-rights-edit',
  templateUrl: './rights-edit.component.html',
  styleUrls: ['./rights-edit.component.scss'],
})
export class RightsEditComponent implements OnInit {
  possibleRoles: Role[] = [];
  autolohnOptions = ['Aus', 'Nicht runden', '5', '10', '15'];
  mailAdress: string;
  currentRight: Right;
  displayRole: Role;
  rendered = false;
  userInfo = new UserInfo(null);
  loginResponse: PwaLoginResponse;

  constructor(
    private activatedRoute: ActivatedRoute,
    private restService: RestService,
    private titleService: Title,
    private dialogService: DialogService,
    private routingService: RoutingService,
    private authorizationService: AuthorizationService,
    private browserService: BrowserService,
    private roleService: RoleService
  ) {}

  async ngOnInit(): Promise<void> {
    this.titleService.setTitle('Mitarbeitereinstellungen');

    const isInternetExplorer = this.browserService.checkForInternetExplorer();
    if (isInternetExplorer) return;

    await this.setUserInfo();

    const validAllocation =
      this.userInfo?.currentAllocation?.statusCode === 200 || this.userInfo?.currentAllocation?.statusCode === 0;
    if (!validAllocation) {
      void this.routingService.navigateTo('/Fehler');
      return;
    }

    const versionOkay = await this.authorizationService.checkVersion(this.userInfo);
    this.loginResponse = versionOkay.response;
    if (!versionOkay?.isOkay) {
      void this.routingService.navigateTo('/Fehler');
      return;
    }

    this.possibleRoles = await this.roleService.getAllRolesFromWebService(this.userInfo);
    // Lizenzcheck im Webservice aus (durch delete release Version), weil hier aus dem frontendGui angefragt wird
    delete this.userInfo.releaseVersion;
    const currentRoleData = await this.roleService.getEmployeesRoleFromWebService(this.userInfo);
    this.userInfo.role = currentRoleData;
    this.currentRight = currentRoleData.right;
    this.tryGetDisplayRole(this.currentRight);
    this.rendered = true;
  }

  async saveEmployee(): Promise<void> {
    const newRoleName = this.displayRole ? this.displayRole.roleName : 'Undefinierte Rolle';
    const saveRole = new Role(this.currentRight, newRoleName);
    this.userInfo.role.roleName = saveRole.roleName;
    this.userInfo.role.right.employeeRights = saveRole.right.employeeRights;
    this.userInfo.role.right.employeeSettings = saveRole.right.employeeSettings;
    this.userInfo.role.right.inAppChangeRights = saveRole.right.inAppChangeRights;
    await this.roleService.sendEmployeeRoleToWebService(this.userInfo);
    location.reload();
  }

  /** @description Sobald ein Switch gedrückt wird - neue Rolle mit aktuellen Rechten anlegen -gucken ob es nicht einer vorhandenen Rolle entspricht  */
  switchActivated(currentRight: Right): void {
    this.displayRole = undefined;
    this.tryGetDisplayRole(currentRight);
  }

  /**@description Versucht das aktuelle Set von Rechten einer bestehenden Rolle zuzuordnen. Wählt diese im Erfolgsfall an */
  tryGetDisplayRole(currentRight: Right): void {
    const index = this.possibleRoles.findIndex(role => GlobalHelper.objectEqualTo(role.right, currentRight));
    if (index === -1) {
      this.displayRole = undefined;
      return;
    }
    this.displayRole = this.possibleRoles[index];
  }

  /**@description Nur aufgerufen, wenn der User aktiv auf eine Rolle klickt (nicht durch programmatische Änderungen wie vorher) */
  onRoleClicked(role: Role): void {
    const roleData: Role = role;
    this.currentRight = roleData.right;
  }

  /**@description Erstellt einen Login link anhand der Daten die vom Handwerk übermittelt wurden und öffnet diesen im standard Email CLient */
  sendLoginLinkViaMail(mailAdress: string, userInfo: UserInfo): void {
    if (GlobalHelper.isNullOrUndefined(mailAdress) || mailAdress === '') {
      this.dialogService.openInformDialog(
        'Fehlende E-Mail Adresse',
        'Sie müssen die E-Mail Adresse angeben, um eine E-Mail zu verschicken',
        'Ok'
      );
      return;
    }
    const subject = 'My blue:app hand:werk Login Link';
    const bodyText =
      'Klicken Sie auf nachfolgenden Link um die Anmeldemaske der my blue:app hand:werk aufzurufen.' +
      'Alternativ können Sie diesen Link auch kopieren und in das Feld "Login-Url" innerhalb des "Manuellen Logins"' +
      ' der my blue:app hand:werk einfügen. ';
    const baseUrl = 'https://handwerk.myblueapp.de';
    const body =
      bodyText +
      baseUrl +
      '/Login/UUID/' +
      userInfo.uuid +
      '/Mandant/' +
      userInfo.mandant +
      '/Employeenumber/' +
      userInfo.employeeNumber +
      '/Branding/loginlink';
    const mailData = 'mailto:' + mailAdress + '?subject=' + subject + '&body=' + body;
    window.open(mailData);
  }

  private async setUserInfo(): Promise<void> {
    const currentUrl = this.activatedRoute.routeConfig.path;

    if (currentUrl === 'Rechte/UUID/:uuid/Mandant/:mandant/Employeenumber/:employeeNumber/Employeename/:employeeName') {
      const employeeNumberValue = this.routingService.getRouteParam('employeeNumber');
      const employeeNameValue = this.routingService.getRouteParam('employeeName').replace('+', ' ');
      const mandantValue = this.routingService.getRouteParam('mandant');
      const uuid = this.routingService.getRouteParam('uuid');
      const userInfo = UserInfoHelper.createUserInfoForRechtegui(
        employeeNumberValue,
        employeeNameValue,
        mandantValue,
        uuid
      );
      this.userInfo = await this.restService.updateAllocationInUserInfo(userInfo);
      GlobalHelper.saveToLocalStorage('guiUserInfoRechte', this.userInfo);
    } else {
      this.userInfo = GlobalHelper.readFromLocalStorage('guiUserInfoRechte');
      this.userInfo = await this.restService.updateAllocationInUserInfo(this.userInfo);
    }
  }
}
