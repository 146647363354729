import { Component, Input } from '@angular/core';
import { BlockSetting } from 'libs/shared/src/lib/entities';

@Component({
  selector: 'app-pdf-setting-editor',
  templateUrl: './pdf-setting-editor.component.html',
  styleUrls: ['./pdf-setting-editor.component.scss'],
})
export class PdfSettingEditorComponent {
  @Input() pdfBaseSetting: BlockSetting;
  @Input() labelShow: string;
  @Input() hideY = false;
  @Input() hideFontsize = false;
  @Input() hideFontFamily = false;
  @Input() hideFontType = false;
  @Input() hideFontColor = false;
  @Input() enabled = true;

  possibleFonts = ['times', 'courier', 'helvetica'];
  possibleFontTypes = ['normal', 'bold', 'italic', 'bolditalic'];
}
