<div class="content" *ngIf="!isErrorPage">
  <div class="content-block">
    <div class="menu-section no-select">
      <app-side-navigation></app-side-navigation>
    </div>
    <div class="content-section">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<div class="content" *ngIf="isErrorPage">
  <router-outlet></router-outlet>
</div>
