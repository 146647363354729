import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SupplierService } from 'apps/frontendgui/src/app/services/supplier.service';
import { OfflinePosition } from 'apps/handwerkPWA/src/app/interfaces';
import { Gewerk, Lieferant, PositionSettings, UserInfo } from 'libs/shared/src/lib/entities';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { PositionService } from 'libs/shared/src/lib/services/position.service';
import { RoleService } from 'libs/shared/src/lib/services/role.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { Subscription } from 'rxjs';

type FilterDataType = 'Artikel' | 'Leistungen';

@Component({
  selector: 'app-item-position-choice',
  templateUrl: './item-position-choice.component.html',
  styleUrls: ['./item-position-choice.component.scss'],
})
export class ItemPositionChoiceComponent implements OnInit {
  subscription: Subscription;
  lieferantenOrGewerke: OfflinePosition[];
  mandant: string;
  positionSettings: PositionSettings;
  userInfo = new UserInfo(null);
  useFilter: boolean;
  headLineText = 'Lieferantenspezifische Artikelsuche';
  mainCaption: FilterDataType = 'Artikel';
  captionDataType = 'Lieferant';
  captionUseCase = 'Artikelverwendung online zulassen';
  captionDataTypeInformation = 'Artikel im Stamm*';
  dataFieldLocation = 'HASMATERIAL';
  explainText =
    '* Die Synchronisationszeit ist abhängig von der Anzahl der Artikel Ihres offline verfügbaren Lieferanten.' +
    'Je größer die Anzahl der Artikel dieses Lieferanten ist, desto länger dauert die Synchronisation!' +
    ' Achtung: Wenn Sie den Filter für die lieferantenspezifische Artikelsuche aktivieren und keine Lieferanten' +
    ' auswählen, werden in der APP auch keine Arikel gefunden bzw. angezeigt!' +
    ' Weitere Informationen erhalten sie ';
  rendered = false;

  constructor(
    private titleService: Title,
    private routingService: RoutingService,
    private roleService: RoleService,
    private positionService: PositionService,
    private supplierService: SupplierService
  ) {}

  async ngOnInit(): Promise<void> {
    this.userInfo = GlobalHelper.readFromLocalStorage('guiUserInfoRechte');
    this.titleService.setTitle(this.headLineText);
    // Lizenzcheck im Webservice aus (durch delete releaseversion), weil hier aus dem frontendgui angefragt wird
    delete this.userInfo.releaseVersion;
    this.userInfo.role = await this.roleService.getEmployeesRoleFromWebService(this.userInfo);
    this.positionSettings = this.userInfo.role.right.positionSettings;
    const currentRoute = this.routingService.lastRoutes[0];
    const isArtikel = currentRoute.includes('Positionswahl');
    if (isArtikel) {
      await this.prepareArtikel(this.userInfo);
      this.rendered = true;
      return;
    }
    await this.prepareLeistungen(this.userInfo);
    this.rendered = true;
  }

  async save(userInfo: UserInfo, type: FilterDataType, useFilter: boolean): Promise<void> {
    switch (type) {
      case 'Artikel': {
        userInfo.role.right.positionSettings.useFilterLieferanten = useFilter;
        break;
      }
      case 'Leistungen': {
        userInfo.role.right.positionSettings.useFilterGewerk = useFilter;
        break;
      }
    }
    await this.roleService.sendEmployeeRoleToWebService(userInfo);
    this.reload();
  }

  async testSyncrotime(mainCaption: 'Artikel' | 'Leistungen'): Promise<void> {
    const positionType = mainCaption === 'Artikel' ? 'Artikel' : 'Leistung';
    await this.positionService.testOfflinePositionSyncTime(this.userInfo, this.positionSettings, positionType);
  }

  /**@description Falls es sich um die Leistungen handelt, werden nun Leistungspezifische Dinge geholt und die defaultEinträge (Artikel) auf Leistungen umgeschrieben */
  private async prepareLeistungen(userInfo: UserInfo): Promise<void> {
    const allGewerke = await this.supplierService.getGewerkeFromWebService(userInfo);
    let currentGewerke = userInfo.role.right.positionSettings.Gewerk;
    if (!currentGewerke) currentGewerke = [];

    const currentGewerkNrsWithChangedSettings = currentGewerke
      .map(obj => (obj.offlineAvailable || obj.synchronize ? obj.NR : null))
      .filter(Boolean);
    const newGewerkenArray: Gewerk[] = [];

    allGewerke.forEach(obj => {
      if (currentGewerkNrsWithChangedSettings.includes(obj.NR)) {
        const currentGewerk = currentGewerke.find(cl => cl.NR === obj.NR);

        obj.synchronize = currentGewerk.synchronize;
        obj.offlineAvailable = currentGewerk.offlineAvailable;
      }

      newGewerkenArray.push(obj);
    });

    this.positionSettings.Gewerk = newGewerkenArray;
    this.lieferantenOrGewerke = this.positionSettings.Gewerk?.filter(gewerk => gewerk.HASLEISTUNG > 0);
    this.headLineText = 'Gewerksspezifische Leistungssuche';
    this.mainCaption = 'Leistungen';
    this.captionDataType = 'Gewerk';
    this.captionUseCase = 'Gewerksverwendung online zulassen';
    this.captionDataTypeInformation = 'Leistungen im Stamm*';
    this.dataFieldLocation = 'HASLEISTUNG';
    this.explainText =
      '* Die Synchronisationszeit ist abhängig von der Anzahl der Leistungen Ihres offline verfügbaren Gewerks.' +
      'Je größer die Anzahl der Leistungen dieses Gewerks ist, desto länger dauert die Synchronisation!' +
      ' Achtung: Wenn Sie den Filter für die gewerkespezifische Leistungssuche aktivieren und keine Gewerke' +
      ' auswählen, werden in der APP auch keine Leistungen gefunden bzw. angezeigt!' +
      ' Weitere Informationen erhalten sie ';
    this.useFilter = userInfo.role.right.positionSettings.useFilterGewerk;
  }

  /**@description Falls es sich um die Artikelseite handelt, werden nun Artikelspezifische dinge geholt */
  private async prepareArtikel(userInfo: UserInfo): Promise<void> {
    const allLieferanten = await this.supplierService.getSuppliersFromWebService(userInfo);
    let currentLieferanten = userInfo.role.right.positionSettings.Lieferanten;
    if (!currentLieferanten) currentLieferanten = [];
    else currentLieferanten = currentLieferanten.filter(lieferant => !GlobalHelper.isNullOrUndefined(lieferant));

    const currentLieferantNrsWithChangedSettings = currentLieferanten
      .map(obj => (obj.offlineAvailable || obj.synchronize ? obj.NR : null))
      .filter(Boolean);
    const newLieferantenArray: Lieferant[] = [];

    allLieferanten.forEach(obj => {
      if (currentLieferantNrsWithChangedSettings.includes(obj.NR)) {
        const currentLieferant = currentLieferanten.find(cl => cl.NR === obj.NR);

        obj.synchronize = currentLieferant.synchronize;
        obj.offlineAvailable = currentLieferant.offlineAvailable;
      }

      newLieferantenArray.push(obj);
    });

    this.positionSettings.Lieferanten = newLieferantenArray;
    this.lieferantenOrGewerke = this.positionSettings.Lieferanten?.filter(lieferant => lieferant.HASMATERIAL > 0);
    this.useFilter = userInfo.role.right.positionSettings.useFilterLieferanten;
  }

  private reload(): void {
    location.reload();
  }
}
