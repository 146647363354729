import { Injectable } from '@angular/core';
import { Gewerk, Lieferant, UserInfo } from '../../../../../libs/shared/src/lib/entities';
import { RestService } from '../../../../../libs/shared/src/lib/services/rest.service';

@Injectable({
  providedIn: 'root',
})
export class SupplierService {
  constructor(private restService: RestService) {}
  /***@description Gets all the suppliers */
  async getSuppliersFromWebService(userInfo: UserInfo): Promise<Lieferant[]> {
    const userInfoAsBody = { ...userInfo };
    delete userInfoAsBody.role; // webservice erwartet string - ist aber für die anfrage unerheblich, daher einfach löschen
    const suppliersData = await this.restService.returnData<Lieferant[]>('Lieferanten', userInfoAsBody);
    const suppliers: Lieferant[] = [];
    for (const supplierData of suppliersData) {
      const supplier = new Lieferant(supplierData);
      suppliers.push(supplier);
    }
    return suppliers;
  }

  async getGewerkeFromWebService(userInfo: UserInfo): Promise<Gewerk[]> {
    const userInfoAsBody = { ...userInfo };
    delete userInfoAsBody.role; // webservice erwartet string - ist aber für die anfrage unerheblich, daher einfach löschen
    const gewerkeData = await this.restService.returnData<Gewerk[]>('Gewerke', userInfoAsBody);
    const gewerke: Gewerk[] = [];
    for (const gewerkData of gewerkeData) {
      const gewerk = new Gewerk(gewerkData);
      gewerke.push(gewerk);
    }
    return gewerke;
  }
}
