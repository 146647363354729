<div *ngIf="rendered">
  <h2 class="page-title">Einstellung für: {{ userInfo.employeeName }}</h2>

  <app-bss-switchbox [labelText]="headLineText" [(value)]="useFilter"></app-bss-switchbox>

  <div *ngIf="useFilter">
    <dx-data-grid class="seventyVh bss-data-grid" [dataSource]="lieferantenOrGewerke">
      <dxo-editing [allowUpdating]="true" [allowAdding]="false" [allowDeleting]="false" mode="cell"> </dxo-editing>

      <dxi-column
        [width]="'40%'"
        [caption]="captionDataType"
        dataField="displayName"
        dataType="string"
        [allowEditing]="false"
      >
      </dxi-column>
      <dxi-column
        [width]="'23%'"
        [caption]="captionUseCase"
        dataField="synchronize"
        dataType="boolean"
        [allowEditing]="true"
      >
      </dxi-column>
      <dxi-column
        [width]="'23%'"
        [caption]="'Offline verfügbar*'"
        dataField="offlineAvailable"
        dataType="boolean"
        [allowEditing]="true"
      >
      </dxi-column>
      <dxi-column
        [width]="'14%'"
        [caption]="captionDataTypeInformation"
        [dataField]="dataFieldLocation"
        dataType="number"
        cssClass="paddingRight"
        [allowEditing]="false"
      >
      </dxi-column>
    </dx-data-grid>

    <hr class="solid" />

    <div style="margin-bottom: 10px">
      {{ explainText }} <a href="/assets/Beschreibung Einstellung PWA offline Artikel.pdf">hier</a> .
    </div>

    <button class="bss-button default-button full-width-button" (click)="testSyncrotime(mainCaption)">
      Synchronisationsgeschwindigkeit der ausgewählten offline {{ mainCaption }} testen
    </button>
  </div>

  <button
    class="bss-button primary-button full-width-button"
    style="margin-top: 15px"
    (click)="save(userInfo, mainCaption, useFilter)"
  >
    Änderungen für {{ userInfo.employeeName }} speichern
  </button>
</div>
