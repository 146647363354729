import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { UserInfo } from 'libs/shared/src/lib/entities';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { globalSettingsUrls, monteurSettingsUrls } from './config/routes';

@Injectable({
  providedIn: 'root',
})
export class RouteGuardService  {
  constructor(private routingService: RoutingService) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const currentUrl = route.routeConfig.path;
    const hasValidUserInfo = this.hasValidUserInfo(currentUrl);
    const isLoginUrl = [
      'Einstellungen/UUID/:uuid/Mandant/:mandant',
      'Rechte/UUID/:uuid/Mandant/:mandant/Employeenumber/:employeeNumber/Employeename/:employeeName',
    ].includes(currentUrl);

    if (!isLoginUrl && !hasValidUserInfo) {
      await this.routingService.navigateTo('Fehler/no-login');
      return false;
    }

    return hasValidUserInfo || isLoginUrl;
  }

  private hasValidUserInfo(currentUrl: string): boolean {
    let userInfo: UserInfo;

    if (globalSettingsUrls.includes(currentUrl)) {
      userInfo = GlobalHelper.readFromLocalStorage('guiUserInfo');
    } else if (monteurSettingsUrls.includes(currentUrl)) {
      userInfo = GlobalHelper.readFromLocalStorage('guiUserInfoRechte');
    }

    return userInfo != null;
  }
}
