<div class="error-message">
  <h5>Fehler</h5>
  <div *ngIf="noLoginError">Es ist keine gültige Anmeldeinformation vorhanden.</div>
  <div *ngIf="!noLoginError">
    Die angegebene Seite wurde nicht gefunden oder es ist ein unbekannter Fehler aufgetreten.
  </div>

  Bitte versuchen Sie die Seite erneut über TopKontor Handwerk aufzurufen. <br /><br />

  Sollte der Fehler weiterhin bestehen, kontaktieren Sie unseren Kundensupport. <br />
  Nähere Informationen erhalten Sie in der
  <a href="https://myblueapp.de/handwerk/produktinformationen/" target="_blank">FAQ</a> .
</div>
