import { Component, OnInit } from '@angular/core';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { globalSettingsUrls, monteurSettingsUrls } from '../../../config/routes';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
})
export class SideNavigationComponent implements OnInit {
  menuItems = [];

  globalSettingsMenuItems = [
    { title: 'Einstellungen', route: 'Einstellungen' },
    { title: 'Textbausteine', route: 'textbausteine' },
    { title: 'E-Mail Einstellungen', route: 'email-einstellungen' },
  ];

  rightsMenuItems = [
    { title: 'Rechte verwalten', route: 'Rechte' },
    { title: 'Rollen verwalten', route: 'Rollenverwaltung' },
    { title: 'Artikelverwendung', route: 'Positionswahl' },
    { title: 'Leistungsverwendung', route: 'Gewerkewahl' },
  ];

  constructor(private routingService: RoutingService) {}

  ngOnInit(): void {
    this.routingService.currentRoute.subscribe(currentRoute => {
      this.setCorrectMenu(currentRoute);
    });
  }

  async navigateTo(path: string): Promise<void> {
    await this.routingService.navigateTo(path);
  }

  private setCorrectMenu(currentRoute: string): void {
    const settingUrls = globalSettingsUrls.slice().map(url => '/' + url);
    const monteurUrls = monteurSettingsUrls.slice().map(url => '/' + url);

    if (currentRoute.startsWith('/Einstellungen/UUID') || settingUrls.includes(currentRoute)) {
      this.menuItems = this.globalSettingsMenuItems;
    } else if (currentRoute.startsWith('/Rechte/UUID') || monteurUrls.includes(currentRoute)) {
      this.menuItems = this.rightsMenuItems;
    }
  }
}
