import { Injectable } from '@angular/core';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  constructor(private dialogService: DialogService) {}

  checkForInternetExplorer(): boolean {
    const isIE = /*@cc_on!@*/ false || !!document['documentMode'];
    if (isIE) {
      this.dialogService.openInformDialog(
        '',
        'Dieser Browser unterstützt nicht den vollen Funktionsumfang der my blue:app. Bitte nutzen Sie einen aktuellen Browser (z.b. Chrome, Firefox, Edge).',
        'Ok'
      );
    }
    return isIE;
  }
}
