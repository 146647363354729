import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DialogService } from '@handwerk-pwa/shared';
import { PwaLoginResponse, Right, Role, UserInfo } from 'libs/shared/src/lib/entities';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { AuthorizationService } from 'libs/shared/src/lib/services/authorization.service';
import { RoleService } from 'libs/shared/src/lib/services/role.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

@Component({
  selector: 'app-roles-edit',
  templateUrl: './roles-edit.component.html',
  styleUrls: ['./roles-edit.component.scss'],
})
export class RolesEditComponent implements OnInit {
  role: Role;
  possibleRoles: Role[];
  newRolename = '';
  userInfo: UserInfo;
  loginResponse: PwaLoginResponse;

  constructor(
    private titleService: Title,
    private dialogService: DialogService,
    private routingService: RoutingService,
    private authorizationService: AuthorizationService,
    private roleService: RoleService
  ) {}

  async ngOnInit(): Promise<void> {
    this.userInfo = GlobalHelper.readFromLocalStorage('guiUserInfoRechte');
    this.titleService.setTitle('Rollen verwalten');
    const response = await this.authorizationService.checkVersion(this.userInfo);
    this.loginResponse = response.response;
    const allRoles = await this.roleService.getAllRolesFromWebService(this.userInfo);
    this.possibleRoles = this.filterAdminAndRestrictedFromRoles(allRoles);
  }

  filterAdminAndRestrictedFromRoles(roles: Role[]): Role[] {
    const filteredRoles: Role[] = [];
    for (const role of roles) {
      const roleName = role.roleName;
      if (roleName !== 'Administrator' && roleName !== 'Restriktiert') {
        filteredRoles.push(role);
      }
    }
    return filteredRoles;
  }

  reload(): void {
    this.routingService.reload();
  }

  /**@description Beim RoleChange aufgerufen */
  onRoleChange(changedRoleEvent: Role): void {
    this.role = changedRoleEvent;
  }

  /**@description Schickt die Rolle zum überschreiben zum Webservice */
  async updateSelectedRole(role: Role): Promise<void> {
    await this.roleService.sendRoleToWebService(role, this.userInfo);
    this.reload();
  }

  /**@description Legt eine neue Rolle an */
  async createNewRole(): Promise<void> {
    if (GlobalHelper.isNullOrUndefined(this.newRolename) || this.newRolename === '') {
      this.dialogService.openInformDialog('Fehler', 'Die neue Rolle benötigt einen Namen.', 'Ok');
      return;
    }
    const existingRoleNames = [];
    for (const role of this.possibleRoles) {
      const name = role.roleName;
      existingRoleNames.push(name);
    }
    if (existingRoleNames.includes(this.newRolename)) {
      this.dialogService.openInformDialog(
        'Fehler',
        'Eine Rolle mit diesem Namen existiert bereits. Wählen Sie einen anderen Namen.',
        'Ok'
      );
      return;
    }
    const adminRight = new Right(null);
    adminRight.createSuperRight();
    const newRole = new Role(adminRight, this.newRolename);
    await this.roleService.sendRoleToWebService(newRole, this.userInfo);
    this.reload();
  }

  async deleteRole(inputRole: Role): Promise<void> {
    await this.roleService.deleteRole(inputRole, this.userInfo);
    this.reload();
  }
}
