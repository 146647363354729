import { OfflinePosition } from 'apps/handwerkPWA/src/app/interfaces';

export class Gewerk implements OfflinePosition {
  UUID: string = null;
  /** GEwerksnnummer */
  NR: string = null;
  BEZ: string = null;
  /** Kundennummer des Lieferanten */
  MODUL: number = null;
  /** Anzahl der leistungen */
  HASLEISTUNG: number = null;
  displayName: string = null;
  offlineAvailable = false;
  synchronize = false;

  constructor(data: Gewerk) {
    Object.assign(this, data);
    this.displayName = this.NR + ' ' + this.BEZ;
  }
}
