<h2 class="page-title">Rollen verwalten</h2>

<div class="dx-fieldset" style="padding-bottom: 10px">
  <div class="dx-fieldset-header">Neue Rolle anlegen</div>

  <div style="margin-bottom: 15px">
    <app-bss-textbox [labelText]="'Name der Rolle'" [(value)]="newRolename"></app-bss-textbox>
  </div>

  <button class="bss-button block-button primary-button" (click)="createNewRole()">anlegen</button>
</div>

<div class="dx-fieldset" style="padding-bottom: 10px">
  <div class="dx-fieldset-header">Rolle</div>

  <div style="margin-bottom: 15px">
    <app-bss-select-box
      [placeholder]="'Rolle auswählen'"
      [showClearButton]="true"
      [items]="possibleRoles"
      [displayExpr]="'roleName'"
      (selectedValue)="onRoleChange($event)"
    ></app-bss-select-box>
  </div>

  <button *ngIf="role" class="bss-button block-button danger-button" (click)="deleteRole(role)">Rolle löschen</button>
</div>

<div *ngIf="role">
  <app-right-core [loginResponse]="loginResponse" [currentRight]="role.right" (newRight)="role.right = $event">
  </app-right-core>

  <button class="bss-button primary-button full-width-button" (click)="updateSelectedRole(role)">
    Änderungen an Rolle {{ role.roleName }} speichern
  </button>
</div>
