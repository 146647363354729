<div *ngIf="rendered">
  <h2 class="page-title">Textbausteine</h2>

  <div class="dx-fieldset">
    <div class="dx-field">
      <div class="dx-field-label">Textbausteine</div>
      <div class="dx-field-value">
        <app-bss-select-box
          class="widthWithoutMargin"
          [items]="textVorlagen"
          (selectedValue)="selectedTextvorlage = $event"
          displayExpr="SuchText"
        ></app-bss-select-box>
      </div>
    </div>

    <app-bss-textbox
      id="bezeichnung"
      [labelText]="'Bezeichnung'"
      [(value)]="selectedTextvorlage.SuchText"
    ></app-bss-textbox>
    <br />
    <app-bss-textarea [labelText]="'Langtext'" [(value)]="selectedTextvorlage.Text"></app-bss-textarea>
  </div>

  <div class="flexContainer">
    <div class="flexContainerItem" style="padding-right: 10px">
      <button class="bss-button primary-button full-width-button" (click)="createNewTextbaustein()">
        Neuer Textbaustein
      </button>
    </div>
    <div class="flexContainerItem" style="padding-right: 10px; padding-left: 10px">
      <button class="bss-button primary-button full-width-button" (click)="saveTextvorlage()">
        Textbaustein Speichern
      </button>
    </div>
    <div class="flexContainerItem" style="padding-left: 10px">
      <button class="bss-button danger-button full-width-button" (click)="deleteTextvorlage()">
        Textbaustein Löschen
      </button>
    </div>
  </div>
</div>
