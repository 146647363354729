<app-data-is-loading *ngIf="!rendered"></app-data-is-loading>

<div *ngIf="rendered">
  <h2 class="page-title">Rechteverwaltung für {{ userInfo.employeeName }}</h2>

  <div class="dx-fieldset">
    <div class="dx-fieldset-header">Login Link per E-Mail verschicken</div>

    <div style="margin-bottom: 15px">
      <app-bss-textbox [labelText]="'E-Mail Adresse'" [(value)]="mailAdress"></app-bss-textbox>
    </div>
    <button class="bss-button block-button primary-button" (click)="sendLoginLinkViaMail(mailAdress, userInfo)">
      ABSENDEN
    </button>
  </div>

  <div class="dx-fieldset" style="padding-bottom: 10px">
    <div class="dx-fieldset-header">Rolle</div>

    <app-bss-select-box
      [placeholder]="'Mitarbeiterspezifische'"
      [items]="possibleRoles"
      [value]="displayRole"
      [displayExpr]="'roleName'"
      (selectedValue)="onRoleClicked($event)"
    ></app-bss-select-box>
  </div>

  <app-right-core
    *ngIf="currentRight"
    [loginResponse]="loginResponse"
    [currentRight]="currentRight"
    (newRight)="switchActivated($event)"
  >
  </app-right-core>

  <button class="bss-button primary-button full-width-button" (click)="saveEmployee()">
    Änderungen für {{ userInfo.employeeName }} speichern
  </button>
</div>
