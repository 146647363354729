<div *ngIf="currentRight">
  <div class="responsive-box">
    <div class="responsive-box-item" style="padding-right: 20px">
      <div class="dx-fieldset">
        <div class="dx-fieldset-header right-header" id="addresses">
          Adressen
          <app-bss-switchbox
            [labelText]="''"
            (click)="displayInfoMessage()"
            [disabled]="true"
            [value]="true"
          ></app-bss-switchbox>
        </div>
        <app-bss-switchbox
          [labelText]="'Kunden anzeigen'"
          (click)="displayInfoMessage()"
          [disabled]="true"
          [(value)]="currentRight.employeeRights.addressRights.showCustomers"
        ></app-bss-switchbox>
        <app-bss-switchbox
          [labelText]="'Kunden anlegen und bearbeiten'"
          [(value)]="currentRight.employeeRights.addressRights.editCustomers"
          (valueChange)="switchActivated()"
        ></app-bss-switchbox>
        <app-bss-switchbox
          [labelText]="'Lieferanten anzeigen'"
          [(value)]="currentRight.employeeRights.addressRights.showSuppliers"
          (valueChange)="switchActivated(); toggleEditSuppliers($event)"
        ></app-bss-switchbox>
        <app-bss-switchbox
          [disabled]="!currentRight.employeeRights.addressRights.showSuppliers"
          [labelText]="'Lieferanten bearbeiten'"
          [(value)]="currentRight.employeeRights.addressRights.editSuppliers"
          (valueChange)="switchActivated()"
        ></app-bss-switchbox>
        <app-bss-switchbox
          [labelText]="'Mitarbeiter anzeigen'"
          [(value)]="currentRight.employeeRights.addressRights.showEmployees"
          (valueChange)="switchActivated(); toggleEditEmployees($event)"
        ></app-bss-switchbox>
        <app-bss-switchbox
          [disabled]="!currentRight.employeeRights.addressRights.showEmployees"
          [labelText]="'Mitarbeiter bearbeiten'"
          [(value)]="currentRight.employeeRights.addressRights.editEmployees"
          (valueChange)="switchActivated()"
        ></app-bss-switchbox>
        <app-bss-switchbox
          [labelText]="'Objektadressen anzeigen'"
          [(value)]="currentRight.employeeRights.addressRights.showObjectAddresses"
          (valueChange)="switchActivated(); toggleEditObjectAddresses($event)"
        ></app-bss-switchbox>
        <app-bss-switchbox
          [disabled]="!currentRight.employeeRights.addressRights.showObjectAddresses"
          [labelText]="'Objektadressen anlegen und bearbeiten'"
          [(value)]="currentRight.employeeRights.addressRights.editObjectAddresses"
          (valueChange)="switchActivated()"
        ></app-bss-switchbox>
        <app-bss-switchbox
          [labelText]="'Freie Adressen anzeigen'"
          [(value)]="currentRight.employeeRights.addressRights.showFreeAddresses"
          (valueChange)="switchActivated(); toggleEditFreeAddresses($event)"
        ></app-bss-switchbox>
        <app-bss-switchbox
          [disabled]="!currentRight.employeeRights.addressRights.showFreeAddresses"
          [labelText]="'Freie Adressen anlegen und bearbeiten'"
          [(value)]="currentRight.employeeRights.addressRights.editFreeAddresses"
          (valueChange)="switchActivated()"
        ></app-bss-switchbox>
        <app-bss-switchbox
          [labelText]="'Offene Posten anzeigen'"
          [(value)]="currentRight.employeeRights.addressRights.showOpenPositions"
          (valueChange)="switchActivated()"
        ></app-bss-switchbox>
        <app-bss-switchbox
          [labelText]="'Umsatz anzeigen'"
          [(value)]="currentRight.employeeRights.addressRights.showTurnover"
          (valueChange)="switchActivated()"
        ></app-bss-switchbox>
      </div>
      <div class="dx-fieldset">
        <div class="dx-fieldset-header right-header" id="Measurement">
          Aufmaß
          <app-bss-switchbox
            [labelText]="''"
            [disabled]="false"
            [(value)]="currentRight.employeeRights.measurementRights.enableModule"
            (valueChange)="switchActivated()"
          ></app-bss-switchbox>
        </div>
      </div>
    </div>

    <div class="responsive-box-item" style="padding-right: 20px">
      <div class="dx-fieldset">
        <div class="dx-fieldset-header right-header" id="Termine">
          Termine
          <app-bss-switchbox
            [labelText]="''"
            [disabled]="false"
            [(value)]="currentRight.employeeRights.appointmentRights.enableModule"
            (valueChange)="
              toggleRightCategory($event, currentRight.employeeRights.appointmentRights); switchActivated()
            "
          ></app-bss-switchbox>
        </div>

        <app-bss-switchbox
          [labelText]="'Alle Termine anzeigen'"
          [(value)]="currentRight.employeeRights.appointmentRights.showAll"
          [disabled]="!currentRight.employeeRights.appointmentRights.enableModule"
          (valueChange)="switchActivated()"
        ></app-bss-switchbox>
        <app-bss-switchbox
          [labelText]="'Termine anlegen'"
          [(value)]="currentRight.employeeRights.appointmentRights.allowCreation"
          [disabled]="!currentRight.employeeRights.appointmentRights.enableModule"
          (valueChange)="switchActivated()"
        ></app-bss-switchbox>
        <app-bss-switchbox
          [labelText]="'Termine editieren'"
          [(value)]="currentRight.employeeRights.appointmentRights.allowEdit"
          [disabled]="!currentRight.employeeRights.appointmentRights.enableModule"
          (valueChange)="switchActivated()"
        ></app-bss-switchbox>
      </div>

      <div class="dx-fieldset">
        <div class="dx-fieldset-header right-header" id="Belege">
          Belege
          <app-bss-switchbox
            [labelText]="''"
            [disabled]="false"
            [(value)]="currentRight.employeeRights.receiptRights.enableModule"
            (valueChange)="switchActivated()"
          ></app-bss-switchbox>
        </div>
      </div>

      <div class="dx-fieldset">
        <div class="dx-fieldset-header right-header" id="repairOrders">
          Reparaturaufträge
          <app-bss-switchbox
            [labelText]="''"
            [disabled]="false"
            [(value)]="currentRight.employeeRights.repairOrderRights.enableModule"
            (valueChange)="
              toggleRightCategory($event, currentRight.employeeRights.repairOrderRights); switchActivated()
            "
          ></app-bss-switchbox>
        </div>
        <app-bss-switchbox
          [disabled]="!currentRight.employeeRights.repairOrderRights.enableModule"
          [labelText]="'Reparaturaufträge erstellen'"
          [(value)]="currentRight.employeeRights.repairOrderRights.createRepairOrder"
          [disabled]="!currentRight.employeeRights.repairOrderRights.enableModule"
          (valueChange)="switchActivated()"
        ></app-bss-switchbox>
        <app-bss-switchbox
          [labelText]="'Bargeldzahlung erlauben'"
          [(value)]="currentRight.employeeRights.repairOrderRights.allowCashPayment"
          [disabled]="!currentRight.employeeRights.repairOrderRights.enableModule"
          (valueChange)="switchActivated()"
        ></app-bss-switchbox>
      </div>

      <div class="dx-fieldset">
        <div class="dx-fieldset-header right-header" id="maintenanceAndService">
          Wartung und Service
          <app-bss-switchbox
            [labelText]="''"
            [disabled]="false"
            [(value)]="currentRight.employeeRights.maintenanceRights.enableModule"
            (valueChange)="
              toggleRightCategory($event, currentRight.employeeRights.maintenanceRights); switchActivated()
            "
          ></app-bss-switchbox>
        </div>
        <!-- Can not be enabled if you don't have MaintenanceSystems enabled -->
        <app-bss-switchbox
          [labelText]="'Serviceaufträge abwickeln'"
          [(value)]="currentRight.employeeRights.maintenanceRights.handleOrder"
          [disabled]="
            !currentRight.employeeRights.maintenanceRights.enableModule ||
            !currentRight.employeeRights.maintenanceRights.enableMaintenanceSystems
          "
          (valueChange)="switchActivated()"
          (disabledClick)="openHandleOrderMessage()"
        ></app-bss-switchbox>
        <!-- Can not be disabled if you have handleOrder enabled -->
        <app-bss-switchbox
          [labelText]="'Anlagen anzeigen'"
          [(value)]="currentRight.employeeRights.maintenanceRights.enableMaintenanceSystems"
          [disabled]="
            !currentRight.employeeRights.maintenanceRights.enableModule ||
            currentRight.employeeRights.maintenanceRights.handleOrder
          "
          (disabledClick)="openMaintenanceSystemMessage()"
          (valueChange)="toggleMaintenanceMeasurementInput($event); switchActivated()"
        ></app-bss-switchbox>
        <app-bss-switchbox
          [labelText]="'Anlagenmesswerte eingeben'"
          [(value)]="currentRight.employeeRights.maintenanceRights.inputMeasuredValues"
          [disabled]="
            !currentRight.employeeRights.maintenanceRights.enableModule ||
            !currentRight.employeeRights.maintenanceRights.enableMaintenanceSystems
          "
          (valueChange)="switchActivated()"
        ></app-bss-switchbox>
      </div>
    </div>
  </div>

  <div class="dx-fieldset">
    <div class="dx-fieldset-header">Einstellungen</div>

    <table class="settings-table" style="width: 100%">
      <tr>
        <th>Bezeichnung</th>
        <th>Voreinstellungen</th>
        <th>Einstellungen in der APP änderbar</th>
      </tr>
      <tr class="setting-row">
        <td>Autolohn Rundung</td>
        <td>
          <app-bss-select-box
            [placeholder]="'Autolohn Rundung'"
            [items]="autoWageOptions"
            [value]="currentRight.employeeSettings.autoWage"
            (selectedValue)="autoWageChanged($event)"
          ></app-bss-select-box>
        </td>
        <td>
          <div class="column-with-switchbox">
            <app-bss-switchbox
              [(value)]="currentRight.inAppChangeRights.autoWage"
              (valueChange)="switchActivated()"
            ></app-bss-switchbox>
          </div>
        </td>
      </tr>
      <tr class="setting-row">
        <td>Preise anzeigen</td>
        <td>
          <div class="column-with-switchbox">
            <app-bss-switchbox
              [(value)]="currentRight.employeeSettings.showPrices"
              (valueChange)="switchActivated()"
            ></app-bss-switchbox>
          </div>
        </td>
        <td>
          <div class="column-with-switchbox">
            <app-bss-switchbox
              [(value)]="currentRight.inAppChangeRights.showPrices"
              (valueChange)="switchActivated()"
            ></app-bss-switchbox>
          </div>
        </td>
      </tr>
      <tr class="setting-row">
        <td>Preise drucken</td>
        <td>
          <div class="column-with-switchbox">
            <app-bss-switchbox
              [(value)]="currentRight.employeeSettings.printPrices"
              (valueChange)="switchActivated()"
            ></app-bss-switchbox>
          </div>
        </td>
        <td>
          <div class="column-with-switchbox">
            <app-bss-switchbox
              [(value)]="currentRight.inAppChangeRights.printPrices"
              (valueChange)="switchActivated()"
            ></app-bss-switchbox>
          </div>
        </td>
      </tr>
      <tr class="setting-row">
        <td>PDF herunterladen/anzeigen</td>
        <td>
          <div class="column-with-switchbox">
            <app-bss-switchbox
              [(value)]="currentRight.employeeSettings.pdfPreview"
              (valueChange)="switchActivated()"
            ></app-bss-switchbox>
          </div>
        </td>
        <td>
          <div class="column-with-switchbox">
            <app-bss-switchbox
              [(value)]="currentRight.inAppChangeRights.pdfPreview"
              (valueChange)="switchActivated()"
            ></app-bss-switchbox>
          </div>
        </td>
      </tr>
      <tr class="setting-row">
        <td>E-Mail aus Auftrag versenden</td>
        <td>
          <div class="column-with-switchbox">
            <app-bss-switchbox
              [(value)]="currentRight.employeeSettings.sendOrderMail"
              (valueChange)="switchActivated()"
            ></app-bss-switchbox>
          </div>
        </td>
        <td>
          <div class="column-with-switchbox">
            <app-bss-switchbox
              [(value)]="currentRight.inAppChangeRights.mailFromOrder"
              (valueChange)="switchActivated()"
            ></app-bss-switchbox>
          </div>
        </td>
      </tr>
      <tr class="setting-row">
        <td>SumUp verwenden</td>
        <td>
          <div class="column-with-switchbox">
            <app-bss-switchbox
              [(value)]="currentRight.employeeSettings.sumUp"
              (valueChange)="switchActivated()"
            ></app-bss-switchbox>
          </div>
        </td>
        <td></td>
      </tr>
      <tr class="setting-row">
        <td>Arbeitszeiten in PDF anzeigen</td>
        <td>
          <div class="column-with-switchbox">
            <app-bss-switchbox
              [(value)]="currentRight.employeeSettings.addWorkingHoursToPDF"
              (valueChange)="switchActivated()"
            ></app-bss-switchbox>
          </div>
        </td>
        <td></td>
      </tr>
    </table>
  </div>
</div>
