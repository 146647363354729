<div style="padding-top: 15px">
  <app-bss-textbox
    [labelText]="'Titel Servicedokument'"
    [(value)]="pdfSettings.titel"
    [maxLength]="280"
  ></app-bss-textbox>

  <hr class="bss-hr" style="margin: 20px 0" />

  <app-bss-switchbox [labelText]="'PDF-Vortext anzeigen'" [(value)]="pdfSettings.showVortext"></app-bss-switchbox>

  <div class="dx-field">
    <div class="dx-field-label">PDF-Vortext</div>
    <div class="dx-field-value">
      <app-bss-select-box
        [items]="selectTextTemplate"
        displayExpr="SuchText"
        [value]="pdfSettings.vortext"
        (selectedValue)="textChanged($event, false)"
      ></app-bss-select-box>
    </div>
  </div>

  <hr class="bss-hr" style="margin: 20px 0" />

  <app-bss-switchbox [labelText]="'PDF-Nachtext anzeigen'" [(value)]="pdfSettings.showNachtext"></app-bss-switchbox>

  <div class="dx-field" style="margin-bottom: 15px">
    <div class="dx-field-label">PDF-Nachtext</div>
    <div class="dx-field-value">
      <app-bss-select-box
        [items]="selectTextTemplate"
        displayExpr="SuchText"
        [value]="pdfSettings.nachtext"
        (selectedValue)="textChanged($event, true)"
      ></app-bss-select-box>
    </div>
  </div>

  <dx-accordion class="settings-accordion" [collapsible]="true" [selectedItems]="[]" [hoverStateEnabled]="false">
    <dxi-item title="Erweiterte Einstellungen">
      <div *ngFor="let name of pdfSettings.settingsNames; last as isLast">
        <div style="padding: 10px 0">
          <app-pdf-setting-editor [pdfBaseSetting]="pdfSettings[name]" [labelShow]="pdfSettings[name].labelText">
          </app-pdf-setting-editor>
        </div>

        <hr *ngIf="!isLast" class="bss-hr" />
      </div>
    </dxi-item>
  </dx-accordion>
</div>
