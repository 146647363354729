<div *ngIf="pdfBaseSetting" [ngStyle]="{ 'background-color': pdfBaseSetting.show && enabled ? 'white' : '#80808026' }">
  <app-bss-switchbox [labelText]="labelShow" [(value)]="pdfBaseSetting.show"></app-bss-switchbox>

  <div class="fieldbox">
    <div class="flexContainer">
      <div class="flexContainerItem">
        <app-bss-numberbox
          [labelText]="'Abstand Links'"
          [(value)]="pdfBaseSetting.startPositionX"
          [disabled]="!pdfBaseSetting.show || !enabled"
        ></app-bss-numberbox>
      </div>
      <div class="valueTypeBox">
        <div class="valueType">mm</div>
      </div>
    </div>

    <div class="flexContainer">
      <div class="flexContainerItem">
        <app-bss-numberbox
          [labelText]="'Abstand Oben'"
          [(value)]="pdfBaseSetting.startPositionY"
          [disabled]="!pdfBaseSetting.show || !enabled"
        ></app-bss-numberbox>
      </div>
      <div class="valueTypeBox">
        <div class="valueType">mm</div>
      </div>
    </div>

    <app-bss-numberbox
      *ngIf="!hideFontsize"
      [labelText]="'Schriftgröße'"
      [(value)]="pdfBaseSetting.fontsize"
      [disabled]="!pdfBaseSetting.show || !enabled"
    ></app-bss-numberbox>

    <div class="dx-field" *ngIf="!hideFontFamily">
      <div class="dx-field-label">Schriftart</div>
      <div class="dx-field-value">
        <app-bss-select-box
          [items]="possibleFonts"
          [value]="pdfBaseSetting.fontFamily"
          (selectedValue)="pdfBaseSetting.fontFamily = $event"
          [disabled]="!pdfBaseSetting.show || !enabled"
        ></app-bss-select-box>
      </div>
    </div>

    <div class="dx-field" *ngIf="!hideFontType">
      <div class="dx-field-label">Schriftstil</div>
      <div class="dx-field-value">
        <app-bss-select-box
          [items]="possibleFontTypes"
          [value]="pdfBaseSetting.fontType"
          (selectedValue)="pdfBaseSetting.fontType = $event"
          [disabled]="!pdfBaseSetting.show || !enabled"
        ></app-bss-select-box>
      </div>
    </div>

    <div class="dx-field" *ngIf="!hideFontType">
      <div class="dx-field-label">Schriftfarbe</div>
      <div class="dx-field-value">
        <dx-color-box
          [(ngModel)]="pdfBaseSetting.fontColor"
          [disabled]="!pdfBaseSetting.show || !enabled"
        ></dx-color-box>
      </div>
    </div>
  </div>
</div>
