import { Component, Input, OnInit } from '@angular/core';
import { PDFSettings, Textvorlage, UserInfo } from 'libs/shared/src/lib/entities';

@Component({
  selector: 'app-document-settings',
  templateUrl: './document-settings.component.html',
  styleUrls: ['./document-settings.component.scss'],
})
export class DocumentSettingsComponent implements OnInit {
  @Input() userInfo: UserInfo;
  @Input() pdfSettings: PDFSettings;
  @Input() textTemplates: Textvorlage[];
  selectTextTemplate: Textvorlage[];

  ngOnInit(): void {
    this.selectTextTemplate = this.textTemplates.map(templateData => new Textvorlage(templateData));
  }

  /**@description Setzt das jeweilige textvorlagen Objekt in den settings */
  textChanged(event: Textvorlage, isNachtext: boolean): void {
    const newVorlage = this.selectTextTemplate.find(template => template.Nummer === event.Nummer);
    if (isNachtext) this.pdfSettings.nachtext = newVorlage;
    else this.pdfSettings.vortext = newVorlage;
  }
}
