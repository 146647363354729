import { Component, OnInit } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { Setting, Textvorlage, UserInfo } from 'libs/shared/src/lib/entities';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { RightsService } from 'libs/shared/src/lib/services/rights.service';

@Component({
  selector: 'app-text-modules',
  templateUrl: './text-modules.component.html',
  styleUrls: ['./text-modules.component.scss'],
})
export class TextModulesComponent implements OnInit {
  userInfo: UserInfo;
  setting: Setting;
  textVorlagen: Textvorlage[];
  selectedTextvorlage: Textvorlage = new Textvorlage(null);
  rendered = false;

  constructor(private dialogService: DialogService, private rightsService: RightsService) {}

  async ngOnInit(): Promise<void> {
    this.userInfo = GlobalHelper.readFromLocalStorage('guiUserInfo');
    this.setting = await this.rightsService.getSettingFromWebService(this.userInfo, true);
    this.textVorlagen = this.setting.textVorlagen;
    this.rendered = true;
  }

  async saveTextvorlage(): Promise<void> {
    const selectedTextvorlage = this.selectedTextvorlage;
    if (!selectedTextvorlage.SuchText) {
      this.dialogService.openInformDialog('Fehler', 'Bezeichnung darf nicht leer sein.', 'Ok');
      return;
    }
    if (!selectedTextvorlage.Text) {
      this.dialogService.openInformDialog('Fehler', 'Langtext darf nicht leer sein.', 'Ok');
      return;
    }
    const existing = this.checkExistingTextvorlage(this.selectedTextvorlage, this.textVorlagen);
    if (existing) {
      this.dialogService.openInformDialog(
        'Fehler',
        'Es gibt bereits einen Textbaustein mit dieser Bezeichnung. Bitte ändern Sie die Bezeichnung.',
        'Ok'
      );
      return;
    }
    this.textVorlagen.push(this.selectedTextvorlage);
    await this.rightsService.sendSettingToWebService(this.setting, this.userInfo);
    location.reload();
  }

  createNewTextbaustein(): void {
    this.selectedTextvorlage = new Textvorlage(null);
    // const bezeichnungsElement = document.getElementById('bezeichnung');
    // const subcontainer = bezeichnungsElement.childNodes[0];
    // const subSubcontainer = subcontainer.childNodes[0];
    // const inputField = subSubcontainer.childNodes[0] as HTMLTextAreaElement;
    // inputField.focus();
  }

  async deleteTextvorlage(): Promise<void> {
    const deleteIndex = this.textVorlagen.indexOf(this.selectedTextvorlage);
    if (deleteIndex === -1) {
      return;
    }
    const deleteIt = await this.dialogService.openConfirmDialog(
      'Textbaustein löschen',
      'Wollen Sie den Textbaustein wirklich löschen?',
      'Löschen',
      'Behalten',
      false
    );
    if (!deleteIt) {
      return;
    }
    this.textVorlagen.splice(deleteIndex, 1);
    await this.rightsService.sendSettingToWebService(this.setting, this.userInfo);
  }

  private checkExistingTextvorlage(newTextvorlage: Textvorlage, textVorlagen: Textvorlage[]): boolean {
    const find = textVorlagen.some(
      vorlage => vorlage.SuchText === newTextvorlage.SuchText || vorlage.Text === newTextvorlage.Text
    );
    return find;
  }
}
