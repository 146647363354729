import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReloadComponent } from 'libs/shared/src/lib/components';
import {
  EmailSettingsComponent,
  ErrorComponent,
  ItemPositionChoiceComponent,
  RightsEditComponent,
  RolesEditComponent,
  SettingComponent,
  TextModulesComponent,
} from './pages';
import { RouteGuardService } from './route-guard.service';

const routes: Routes = [
  /** Shared */
  { path: 'Fehler', component: ErrorComponent },
  { path: 'Fehler/no-login', component: ErrorComponent },
  { path: 'reload/:route', component: ReloadComponent },

  /** Global settings */
  { path: 'Einstellungen/UUID/:uuid/Mandant/:mandant', component: SettingComponent, canActivate: [RouteGuardService] },
  { path: 'Einstellungen', component: SettingComponent, canActivate: [RouteGuardService] },
  { path: 'textbausteine', component: TextModulesComponent, canActivate: [RouteGuardService] },
  { path: 'email-einstellungen', component: EmailSettingsComponent, canActivate: [RouteGuardService] },

  /** Right settings */
  {
    path: 'Rechte/UUID/:uuid/Mandant/:mandant/Employeenumber/:employeeNumber/Employeename/:employeeName',
    component: RightsEditComponent,
    canActivate: [RouteGuardService],
  },
  { path: 'Rechte', component: RightsEditComponent, canActivate: [RouteGuardService] },
  { path: 'Rollenverwaltung', component: RolesEditComponent, canActivate: [RouteGuardService] },
  { path: 'Positionswahl', component: ItemPositionChoiceComponent, canActivate: [RouteGuardService] },
  { path: 'Gewerkewahl', component: ItemPositionChoiceComponent, canActivate: [RouteGuardService] },

  /** muss unten bleiben */
  { path: '**', redirectTo: '/Fehler' },
];
@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
