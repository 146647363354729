import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from '@handwerk-pwa/shared';
import { Briefpapier, Setting, Textvorlage, UserInfo } from 'libs/shared/src/lib/entities';
import { GlobalHelper, HTMLInputEvent } from 'libs/shared/src/lib/helper/globalHelper';
import { AuthorizationService } from 'libs/shared/src/lib/services/authorization.service';
import { BrowserService } from 'libs/shared/src/lib/services/browser.service';
import { PrintService } from 'libs/shared/src/lib/services/print.service';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { RightsService } from 'libs/shared/src/lib/services/rights.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { PDFSettingSet, SettingSetType } from '../../../entities';
import { UserInfoHelper } from '../../../helper/userInfoHelper';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss'],
})
export class SettingComponent implements OnInit {
  setting: Setting;
  newTextvorlage = new Textvorlage(null);
  briefPapier: Briefpapier;
  showBriefpapier = false;
  currentFileName = '';
  currentNachtext: Textvorlage;
  currentVortext: Textvorlage;
  selectedTextvorlage = new Textvorlage(null);
  textvorlagenAuswahl: Textvorlage[];
  textbausteinSelected = false;
  newText = true;
  qrcodeImage = 'assets/img/handwerkpwaqr.png';
  userInfo: UserInfo;
  pdfSettingSets: PDFSettingSet[] = [];
  rendered = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private dialogService: DialogService,
    private printService: PrintService,
    private restService: RestService,
    private rightsService: RightsService,
    private routingService: RoutingService,
    private authorizationService: AuthorizationService,
    private browserService: BrowserService
  ) {}

  async ngOnInit(): Promise<void> {
    this.titleService.setTitle('Globale Einstellungen');

    const isInternetExplorer = this.browserService.checkForInternetExplorer();
    if (isInternetExplorer) return;

    await this.setUserInfo();

    const validAllocation =
      this.userInfo?.currentAllocation?.statusCode === 200 || this.userInfo?.currentAllocation?.statusCode === 0;
    if (!validAllocation) {
      void this.routingService.navigateTo('/Fehler');
      return;
    }
    const versionOkay = await this.authorizationService.checkVersion(this.userInfo);
    if (!versionOkay?.isOkay) {
      void this.routingService.navigateTo('/Fehler');
      return;
    }

    this.setting = await this.rightsService.getSettingFromWebService(this.userInfo, true);
    this.pdfSettingSets.push(new PDFSettingSet('Reparaturauftrag', this.setting.PDFSettings));
    this.pdfSettingSets.push(new PDFSettingSet('Serviceauftrag', this.setting.PDFSettingsServiceAuftrag));
    this.briefPapier = this.setting.briefPapier;
    if (!GlobalHelper.isNullOrUndefined(this.briefPapier)) {
      this.currentFileName = this.briefPapier.fileName;
    }
    this.rendered = true;
  }

  async save(): Promise<void> {
    await this.rightsService.sendSettingToWebService(this.setting, this.userInfo);
    location.reload();
  }

  async addBackgroundPaper(event: Event): Promise<void> {
    const files = (event as HTMLInputEvent).target.files;
    const response = [];
    for (const file of Array.from(files)) {
      if (file.type !== 'image/jpeg' && file.type !== 'image/jpg') {
        this.dialogService.openInformDialog('Fehler', 'Erlaubte Dateitypen sind JPEG oder JPG.', 'Ok');
        return;
      }
      response.push(this.savePdf(file));
    }
    await Promise.all(response);
  }

  /**@description Ließt und speichert Base64 des PDF Briefpapiers */
  async savePdf(file: File): Promise<void> {
    const base64Image = await this.toBase64(file);
    const fileSizeInKilo = file.size / 1000;
    if (fileSizeInKilo > 5000) {
      this.dialogService.openInformDialog('Fehler', 'Datei darf nicht größer als 5MB sein', 'Ok');
      return;
    }
    const filename = file.name;
    const briefPapier = new Briefpapier(filename, base64Image);
    this.setting.briefPapier = briefPapier;
    this.briefPapier = briefPapier;
  }

  async toBase64(file: File): Promise<string> {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (): void => {
        resolve(reader.result as string);
      };
      reader.onerror = (error): void => {
        reject(error);
      };
    });
  }

  resetBriefpapier(): void {
    this.setting.briefPapier = undefined;
    this.briefPapier = undefined;
  }

  showPdfpreview(type: SettingSetType): void {
    switch (type) {
      case 'Reparaturauftrag': {
        this.printService.printExampleRepairOrder(this.setting);
        break;
      }
      case 'Serviceauftrag': {
        this.printService.printExampleServiceOrder(this.setting);
        break;
      }
    }
  }

  private async setUserInfo(): Promise<void> {
    const currentUrl = this.activatedRoute.routeConfig.path;

    if (currentUrl === 'Einstellungen/UUID/:uuid/Mandant/:mandant') {
      const mandantValue = this.routingService.getRouteParam('mandant');
      const uuid = this.routingService.getRouteParam('uuid');
      const userInfo = UserInfoHelper.createUserInfoForRechtegui('guiNumber', 'guiName', mandantValue, uuid);
      this.userInfo = await this.restService.updateAllocationInUserInfo(userInfo);
      GlobalHelper.saveToLocalStorage('guiUserInfo', this.userInfo);
    } else {
      this.userInfo = GlobalHelper.readFromLocalStorage('guiUserInfo');
      this.userInfo = await this.restService.updateAllocationInUserInfo(this.userInfo);
    }
  }
}
