<app-data-is-loading *ngIf="!rendered"></app-data-is-loading>

<div *ngIf="rendered">
  <h2 class="page-title">Einstellungen für hand:werk</h2>

  <div class="flexContainer">
    <div class="flexContainerItem" style="margin: auto; word-break: normal">
      Die my:blue:app hand:werk können Sie auf Ihr mobiles Endgerät laden, indem Sie auf Ihrem Endgerät die URL
      handwerk.myblueapp.de im Browser aufrufen oder den nebenstehenden QR-Code scannen.
    </div>
    <div>
      <img [src]="qrcodeImage" style="max-width: 150px" />
    </div>
  </div>

  <div class="dx-fieldset">
    <div class="dx-fieldset-header">Briefpapier</div>

    <div>
      <div class="flexContainer" style="margin-bottom: 10px">
        <div class="flexContainerItem">Aktuelles Briefpapier:</div>
        <div class="flexContainerItem">
          {{ setting.briefPapier?.fileName }}
        </div>
      </div>
      <div class="flexContainer" style="margin-bottom: 10px">
        <div class="flexContainerItem">Briefpapier hochladen:</div>
        <div class="flexContainerItem">
          <input #uploader hidden type="file" accept=".jpg,.jpeg" (change)="addBackgroundPaper($event)" />
          <button class="bss-button default-button" style="width: 49%; margin-right: 2%" (click)="uploader.click()">
            Hochladen
          </button>
          <button class="bss-button default-button" style="width: 49%" (click)="resetBriefpapier()">
            Zurücksetzen
          </button>
        </div>
      </div>
      <div class="flexContainer">
        <div class="flexContainerItem"></div>
        <div class="flexContainerItem">
          <button class="bss-button primary-button full-width-button" (click)="save()">Speichern</button>
        </div>
      </div>
    </div>
  </div>

  <div class="dx-fieldset">
    <div class="dx-fieldset-header">Dokumenteinstellungen</div>

    <dx-tab-panel
      *ngIf="setting"
      #tabPanel
      class="bss-tab-panel"
      [dataSource]="pdfSettingSets"
      [selectedIndex]="0"
      [loop]="false"
      [animationEnabled]="true"
      [swipeEnabled]="true"
    >
      <div *dxTemplate="let pdfSettingSet of 'title'">
        <span>{{ pdfSettingSet.name }}</span>
      </div>
      <div *dxTemplate="let pdfSettingSet of 'item'" class="twentypxPadding">
        <app-document-settings
          [pdfSettings]="pdfSettingSet.pdfSettings"
          [userInfo]="userInfo"
          [textTemplates]="setting.textVorlagen"
        >
        </app-document-settings>

        <button
          class="bss-button default-button full-width-button"
          style="margin-top: 10px"
          (click)="showPdfpreview(pdfSettingSet.name)"
        >
          Vorschau anzeigen
        </button>
      </div>
    </dx-tab-panel>
  </div>

  <button class="bss-button primary-button full-width-button" (click)="save()">Speichern</button>
</div>
