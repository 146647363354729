import { UserInfo } from 'libs/shared/src/lib/entities';

export class UserInfoHelper {
  static createUserInfoForRechtegui(
    monteurNummer: string,
    monteurName: string,
    mandantValue: string,
    handwerkUuid: string,
  ): UserInfo {
    const userInfo = new UserInfo(null);
    userInfo.monteur = monteurNummer;
    userInfo.mandant = mandantValue;
    userInfo.employeeName = monteurName;
    userInfo.uuid = handwerkUuid;

    userInfo.employeeNumber = monteurNummer;
    userInfo.pin = 'guiPin';
    userInfo.password = 'guiPassword';
    userInfo.branding = 'guiBranding';
    userInfo.user = 'guiUser';

    return userInfo;
  }
}
