import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  noLoginError = false;

  constructor(private titleService: Title, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.titleService.setTitle('Fehler');
    this.setNoLoginError();
  }

  private setNoLoginError(): void {
    const currentRoute = this.route.routeConfig.path;
    this.noLoginError = currentRoute === 'Fehler/no-login' ? true : false;
  }
}
