import { PDFSettings } from 'libs/shared/src/lib/entities';

export type SettingSetType = 'Reparaturauftrag' | 'Serviceauftrag';

export class PDFSettingSet {
  name: SettingSetType;
  pdfSettings: PDFSettings;

  constructor(name: SettingSetType, pdfSettings: PDFSettings) {
    this.name = name;
    this.pdfSettings = pdfSettings;
  }
}
