<div *ngIf="rendered">
  <h2 class="page-title">E-Mail Einstellungen</h2>

  <div class="dx-fieldset">
    <div class="dx-fieldset-header">SMTP-Einstellungen</div>

    <div class="fieldbox">
      <app-bss-textbox [labelText]="'SMTP-Benutzername'" [(value)]="emailConnectionInfo.smtpUser"></app-bss-textbox>
      <app-bss-textbox
        [labelText]="'SMTP-Passwort'"
        [(value)]="emailConnectionInfo.smtpPassword"
        [mode]="'password'"
      ></app-bss-textbox>
      <app-bss-textbox [labelText]="'SMTP-Server'" [(value)]="emailConnectionInfo.smtpServer"></app-bss-textbox>
      <app-bss-numberbox [labelText]="'SMTP-Port'" [(value)]="emailConnectionInfo.smtpPort"></app-bss-numberbox>
    </div>

    <app-bss-select-box
      [showClearButton]="true"
      [labelText]="'E-Mail Textvorlage'"
      [placeholder]="'auswählen'"
      [items]="setting.textVorlagen"
      [value]="currentEmailtext"
      [displayExpr]="'SuchText'"
      (selectedValue)="onEmailtextChange($event)"
    ></app-bss-select-box>
  </div>

  <button class="bss-button primary-button full-width-button" (click)="save()">Speichern</button>

  <div class="dx-fieldset" style="padding-top: 20px">
    <div class="dx-fieldset-header">E-Mail Test</div>

    <app-mailtest [settings]="setting"></app-mailtest>
  </div>
</div>
