import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PwaLoginResponse, Right } from 'libs/shared/src/lib/entities';
import { FeatureNames } from 'libs/shared/src/lib/entities/models/user/FeatureCheck';
import { Module } from 'libs/shared/src/lib/entities/models/user/rights/RightCategory';
import { DialogService } from 'libs/shared/src/lib/services/dialog.service';

@Component({
  selector: 'app-right-core',
  templateUrl: './right-core.component.html',
  styleUrls: ['./right-core.component.scss'],
})
export class RightCoreComponent {
  @Input() currentRight: Right;
  @Output() newRight = new EventEmitter<Right>();
  @Input() loginResponse: PwaLoginResponse;
  featureNames = FeatureNames;
  autoWageOptions = ['Aus', 'Nicht runden', '5', '10', '15'];

  constructor(private dialogService: DialogService) {}

  autoWageChanged(event: string): void {
    this.currentRight.employeeSettings.autoWage = event;
    this.newRight.emit(this.currentRight);
  }

  switchActivated(): void {
    this.newRight.emit(this.currentRight);
  }

  displayInfoMessage(): void {
    this.dialogService.openInformDialog(
      'Hinweis',
      'Die Anzeige der Kunden ist nicht abschaltbar, da ansonsten auch keine Termine und/oder Reparaturaufträge anzeigbar sind!',
      'Ok'
    );
  }

  repairOrderRightChange(newRight: boolean): void {
    if (!newRight) {
      this.currentRight.employeeRights.repairOrderRights.createRepairOrder = false;
      this.currentRight.employeeRights.repairOrderRights.allowCashPayment = false;
    }
  }

  toggleEditObjectAddresses(newRight: boolean): void {
    if (!newRight) this.currentRight.employeeRights.addressRights.editObjectAddresses = false;
  }

  toggleEditSuppliers(newRight: boolean): void {
    if (!newRight) this.currentRight.employeeRights.addressRights.editSuppliers = false;
  }

  toggleEditEmployees(newRight: boolean): void {
    if (!newRight) this.currentRight.employeeRights.addressRights.editEmployees = false;
  }

  toggleEditFreeAddresses(newRight: boolean): void {
    if (!newRight) this.currentRight.employeeRights.addressRights.editFreeAddresses = false;
  }

  openHandleOrderMessage(): void {
    this.dialogService.openInformDialog(
      'Hinweis',
      'Das bearbeiten der Serviceaufträge kann nicht angeschaltet werden, da es das Anzeigen von Anlagen benötigt',
      'Ok'
    );
  }

  openMaintenanceSystemMessage(): void {
    this.dialogService.openInformDialog(
      'Hinweis',
      'Das Anzeigen von Anlagen kann nicht abgeschaltet werden, da diese für Serviceaufträge benötigt werden. Bitte schalten sie diese vorher aus',
      'Ok'
    );
  }

  toggleMaintenanceMeasurementInput(newRight: boolean): void {
    if (!newRight) {
      this.currentRight.employeeRights.maintenanceRights.inputMeasuredValues = false;
      // turn off the module since enableMeasurementSystems is currently the last right you can off, if none are on it doesn't make sense to have the module enabled
      this.currentRight.employeeRights.maintenanceRights.enableModule = false;
    }
  }

  toggleRightCategory(active: boolean, rightCategory: Module): void {
    if (active) rightCategory.allowEverything();
    else rightCategory.restrictEverything();
  }
}
